$primary: #5b00f3; /* MAIN COLOR */
$secondary: #C47F2D; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Abel|Lora');

.flash {
	display:none;
}
h1,h2,h3 {
	font-family: 'Lora', serif;
	color: $primary;
}

p {
	font-family: 'Abel', sans-serif;
	font-size: 2.3em;
	@media (max-width: 1199px) {
		font-size: 2em;
	}
	@media (max-width: 991px) {
		font-size: 1.8em;
	}
	@media (max-width: 767px) {
		font-size: 1.4em;
	}
}
/** NAV START **/
nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;
	
	.navbar-nav {

	    > li > a {
			text-align: center;
			height: 90px;
			display: flex;
			align-items: center;


			@media (max-width: 767px) {
			    height: 30px;
			    display: inline-block;
			    padding: 5px;
			}

		    &:hover {
		    	background: $primary;
		    	color: lighten($primary, 50%);
		    }

			&:focus {
			  background: transparent;
			  color: #777;
			  outline: 0;
			}
		}
	}
}


.navbar-right {
	margin-top: 0px;
}


.navbar-toggle {
	margin-top: 20px;
}


/** LOGIN FORM **/
@mixin btn1 {
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}


@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}


.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}



.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}


input#username {
	margin-bottom: 20px;
}


/** END LOGIN FORM **/
footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	p {
		font-size: 1.5em;
		@media (max-width: 767px) {
			font-size: 1.3em;
		}
	}
	a {
		color: $footerLinks;
		white-space: nowrap;

		&:hover{
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $wht;
			outline: 0 !important;
 			text-decoration: none;
		}
	}
}


input#username, input#password {
	width: 100%;
}


@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}


select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    } 
}

.banner {
	background: url(../img/banner.jpg) no-repeat center;
	background-size: cover;
	padding: 10em 0em;
}

.pic1 {
	background: url(../img/pic1.jpg) no-repeat;
	height: 370px;
	background-position: 50% 50%;
	width: 50%;
	padding: 0;
	background-clip: content-box;
	background-size: cover;
	@media (max-width: 850px) {
		width: 100%;
	}
	@media (max-width: 767px) {
		background-position: 50% 30%;
		height: 300px;
	}
}
.pic2 {
	background: url(../img/pic2.jpg) no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	height: 370px;
	margin-top: 15px;
	background-clip: content-box;
	width: 50%;
	@media (max-width: 850px) {
		width: 100%;
		padding: 0;
		margin: 0;
	}
	@media (max-width: 767px) {
		background-position: 50% 0%;
		height: 300px;
	}
}
.pic3 {
	background: url(../img/pic3.jpg) no-repeat;
	background-size: cover;
	height: 250px;
	padding: 0;
	background-position: 50% 10%;
	background-clip: content-box;
	width: 70%;
	@media (max-width: 850px) {
		width: 100%;
		padding: 0;
		margin: 0;
		height: 370px;
	}
}
.pic4 {
	background: url(../img/pic4.jpg) no-repeat;
	background-size: cover;
	height: 200px;
	background-position: 50% 50%;
	background-clip: content-box;
	width: 40%;
	padding: 0;
	@media (max-width: 850px) {
		width: 100%;
		padding: 0;
		margin: 0;
		height: 300px;
		display: none;
	}
}
.pic5 {
	background: url(../img/pic5.jpg) no-repeat;
	background-size: cover;
	height: 250px;
	background-clip: content-box;
	width: 60%;
	padding: 0 0 0 15px;
	background-position: 50% 20%;
	@media (max-width: 850px) {
		width: 100%;
		padding: 0;
		margin: 0;
		height: 370px;
	}
}
.customContainer {
	width: 90%;
}

.custom-md-6 {
	width: 40%;
	@media (max-width: 767px) {
		width: 100%;
	}
}
.custom-md-6-2 {
	width: 60%;
	@media (max-width: 767px) {
		width: 100%;
		display: none;
	}
}
.padTopBtm {
	padding: 15px 0;
	@media (max-width: 850px) {
		padding: 0;
	}
}
.padTopBtm2 {
	padding: 50px 15px;
}
.title {
	margin-bottom: 30px;
}
.pic .overlay {
	background: rgba($primary, 0.5);
	height: 100%;
	opacity: 0;
	transition: all 0.3s ease-in-out;
	p {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		font-size: 2em;	
		opacity: 0;	
		transition: all 0.3s ease-in-out;
	}

}
.pic {
	.overlay {
		@media (max-width: 1024px) {
			opacity: 1;
		}
		p {
		@media (max-width: 1024px) {
			opacity: 1;
		}	
		}
	}
	&:hover .overlay, &:hover .overlay p {
		opacity: 1;

	}
}
.bg {
	background: url(../img/banner.jpg) no-repeat;
	background-size: cover;
	padding: 100px 0px;
	@media (max-width: 767px) {
		padding: 50px 15px;
	}
	@media (max-width: 600px) {
		background-position: 90% 50%;
	}

	.txtBox {
		background: rgba(255,255,255,0.5);
		@media (max-width: 767px) {
			background: rgba(255,255,255,0.8);
		}
		h1 {
			@media (max-width: 767px) {
				font-size: 2em;
			}
		}
	}
}
.headerBg {
	background: url(../img/bg.jpg) no-repeat;
	background-size: cover;
	padding: 0 0 20px 0;
	margin: 0;
	background-position: 50% 50%;
}

.formSection {
	h1 {
		@media (max-width: 767px) {
			font-size: 2em;
		}
	}
	p {
		@media (max-width: 991px) {
			margin-bottom: 30px;
		}
	}
}
.btmImg {
	@media (max-width: 991px) {
		max-width: 350px;
		width: 100%;
		display: block;
		margin: 0 auto;
	}
}
.fixedNav {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 999;
}

h1 {
	@media (max-width: 991px) {
		font-size: 2em;
	}
}
.btmTxt {
	p {
		margin-bottom: 30px;
	}
}

.top-pad {
	font-family: 'Abel', sans-serif;
	p {
		font-size: 1.3em;
	}
}